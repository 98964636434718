import React, { useState } from "react";
import planes from "../assets/planes.json";
import styles from "./styles/planes.module.css";

const Planes = () => {
  const [planActivo, setPlanActivo] = useState(null);

  const toggleDetalles = (indice) => {
    setPlanActivo(planActivo === indice ? null : indice);
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Planes Empresas y Emprendedores</h1>
      <p className={styles.description}>
        Encuentra el plan que mejor se ajuste a las necesidades de su empresa o
        emprendimiento y contactanos. Asegure el optimo funcionamiento de sus
        plataformas y servicios con nosotros.
      </p>
      <div className={styles.row}>
        {planes.map((plan, index) => (
          <div key={index} className={styles.column}>
            <div className={styles.card}>
              <div className={styles.cardTitle}>{plan.titulo}</div>
              <div className={styles.cardDescription}>{plan.descripcion}</div>
              <button
                className={styles.button}
                onClick={() => toggleDetalles(index)}
                type="button"
              >
                {planActivo === index ? "Ocultar Detalles" : "Más Información"}
              </button>
              <div className={styles.detailsActiveContainer}>
                <div
                  className={`${styles.details} ${
                    planActivo === index ? styles.detailsActive : ""
                  }`}
                >
                  <ul>{plan.detalles}
                    {[
                      plan.detalles1,
                      plan.detalles2,
                      plan.detalles3,
                      plan.detalles4,
                      plan.detalles5,
                      plan.detalles6,
                      plan.detalles7,
                      plan.detalles8,
                    ]
                      .filter((detalle) => detalle && detalle.trim() !== "")
                      .map((detalle, i) => (
                        <li key={i}>{detalle}</li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Planes;
