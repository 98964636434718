import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faWhatsapp,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import styles from "./styles/footer.module.css";
import logoPCT from "../assets/logo2-rbg.png";

const AltFooter = () => {
  return (
    <footer className={styles.footerContainer}>
      <div className={`${styles.footerContent} ${styles.footerContentAbout}`}>
        <div className={`${styles.footerSection} ${styles.footerSectionAbout}`}>
          {/* <div className={styles.logoContainer}> */}
            <img
              src={logoPCT}
              alt="PCT Solution Logo"
              className={styles.footerLogo}
            />
            {/* <h1 className={styles.siteName}>
              PCT<p className={styles.subName}>Solution</p>
            </h1> */}
          {/* </div> */}
          <p className={styles.aboutText}>
            Especializados en soluciones tecnológicas, dedicados a ofrecer
            servicios de desarrollo web, asesoría tecnológica y soporte técnico
            especializado para empresas, emprendedores y hogares.
          </p>
        </div>

        <div className={`${styles.footerSection} ${styles.footerSectionLinks}`}>
          <h4 className={styles.footerHeading}>Enlaces Rápidos</h4>
          <ul>
            <li>
              <Link to="/nosotros">Nosotros</Link>
            </li>
            <li>
              <Link to="/hogar/servicios">Servicios</Link>
            </li>
            <li>
              <Link to="/empresas/planes">Planes</Link>
            </li>
            <li>
              <Link to="/contacto">Contacto</Link>
            </li>
            <li>
              <Link to="/preguntas">Preguntas Frecuentes</Link>
            </li>
            <li>
              <Link to="/noticias">Noticias</Link>
            </li>
          </ul>
        </div>

        <div
          className={`${styles.footerSection} ${styles.footerSectionContact}`}
        >
          <h4 className={styles.footerHeading}>Contáctanos</h4>
          <div className={styles.contactItem}>
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              className={styles.contactIcon}
            />
            <a
              href="https://maps.app.goo.gl/y6gN5JqGRvV2FJbY9"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Ubicación"
            >
              <span>Leonardo Da Vinci 4813, Maipú - Chile</span>
            </a>
          </div>
          <div className={styles.contactItem}>
            <FontAwesomeIcon icon={faPhone} className={styles.contactIcon} />
            <a href="tel:+56940175324">+56 (9) 4017-5324</a>
            {/*
            <span style={{ margin: "0 5px" }}>/</span>
            <a href="tel:+58414XXXXXXX">+56 (9) XXXX-XXXX</a>
            */}
          </div>
          <div className={styles.contactItem}>
            <FontAwesomeIcon icon={faEnvelope} className={styles.contactIcon} />
            <a href="mailto:info@pctsolution.com">info@pctsolution.com</a>
          </div>

          <div className={styles.socialIcon}>
            <a
              href="https://www.facebook.com/profile.php?id=61574462619552"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a
              href="https://x.com/pctsolution"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a
              href="https://www.instagram.com/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a
              href="https://wa.me/56940175324/?text=Hola%20,%20estoy%20interesado/a%20en…"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="WhatsApp"
            >
              <FontAwesomeIcon icon={faWhatsapp} />
            </a>
            <a
              href="https://github.com/Sheng-Tech"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Github"
            >
              <FontAwesomeIcon icon={faGithub} />
            </a>
          </div>
        </div>
      </div>

      <div className={styles.footerBottom}>
        <p>
          &copy; {new Date().getFullYear()} PCT Solution. Todos los derechos
          reservados.
        </p>
      </div>
    </footer>
  );
};

export default AltFooter;
