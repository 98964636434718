import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Base from "../pages/Base";
import Home from "../pages/Home";
import Servicios from "../pages/Servicios";
import Contacto from "../pages/Contacto";
import PreguntasFrecuentes from "../pages/PreguntasFrecuentes";
import UnderConstruction from "../pages/UnderConstruction";
import QuienesSomos from "../pages/About";
import ScrollToTop from "../components/ScrollToTop";
import NotFound from "../pages/NotFound";
import Noticias from "../pages/Noticias";
import Planes from "../pages/Planes";

const AppRoutes = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Base />}>
          <Route index element={<Home />}></Route>
          <Route path="/hogar/servicios" element={<Servicios />}></Route>
          <Route path="/empresas/planes" element={<Planes />}></Route>
          <Route path="/nosotros" element={<QuienesSomos />}></Route>
          <Route path="/contacto" element={<Contacto />}></Route>
          <Route path="/preguntas" element={<PreguntasFrecuentes />}></Route>
          <Route path="/noticias" element={<Noticias />}></Route>

          <Route path="/mantenimiento" element={<UnderConstruction />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRoutes;
