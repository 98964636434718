import styles from "./styles/socialMedia.module.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

function SocialMedia() {
  return (
    <div className={styles.mediaContainer}>
      <ul className={styles.title}>
        <li className={styles.items}>
          <a
            href="https://wa.me/56940175324/?text=Hola%20,%20estoy%20interesado/a%20en…"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa-brands fa-whatsapp redesSociales__img fa-2x"></i>
          </a>
        </li>
        <li className={styles.items}>
          <a
            href="https://www.facebook.com/profile.php?id=61574462619552"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa-brands fa-facebook redesSociales__img fa-2x"></i>
          </a>
        </li>
        <li className={styles.items}>
          <a
            href="https://www.instagram.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa-brands fa-instagram redesSociales__img fa-2x"></i>
          </a>
        </li>
      </ul>
    </div>
  );
}

export default SocialMedia;
