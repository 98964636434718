import React from "react";
import useOnScreen from "../hooks/useOnScreen";
import styles from "./styles/animatedCard.module.css";

const AnimatedCard = ({ children, className = "" }) => {
  const [ref, isVisible] = useOnScreen(0.5);

  return (
    <div
      ref={ref}
      className={`${className} ${styles.hidden} ${isVisible ? styles.visible : ""}`}
    >
      {children}
    </div>
  );
};

export default AnimatedCard;