import styles from "./styles/preguntasFrecuentes.module.css";
import preguntasFrecuentesData from "../assets/faqs.json";
import { useState } from "react";
import AnimatedCard from "../components/AnimatedCard";
import AnimatedXCard from "../components/AnimatedXCard";

const PreguntasFrecuentes = () => {
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState(
    Object.keys(preguntasFrecuentesData)[0]
  );

  return (
    <div className={styles.container}>
      <AnimatedCard className={styles.hero}>
        <h1 className={styles.title}>Preguntas Frecuentes</h1>

        <div className={styles.nav}>
          {Object.keys(preguntasFrecuentesData).map((categoria) => (
            <button
              key={categoria}
              className={`${styles.navButton} ${
                categoria === categoriaSeleccionada ? styles.active : ""
              }`}
              onClick={() => setCategoriaSeleccionada(categoria)}
            >
              {categoria}
            </button>
          ))}
        </div>
      </AnimatedCard>
      <div className={styles.contentSection}>
        <div className={styles.categoryTitle}></div>
        <div key={categoriaSeleccionada} className={styles.content}>
          {preguntasFrecuentesData[categoriaSeleccionada][
            "Preguntas Frecuentes"
          ].map((item, index) => {
            const direction = index % 2 === 0 ? "left" : "right";
            return (
              <AnimatedXCard
                key={index}
                direction={direction}
                className={styles.faqItem}
                style={{ transitionDelay: `${index * 600}ms` }}
              >
                <details className={styles.details}>
                  <summary className={styles.question}>{item.Pregunta}</summary>
                  <p className={styles.answer}>{item.Respuesta}</p>
                </details>
              </AnimatedXCard>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PreguntasFrecuentes;
