import React, { useState } from "react";
import styles from "./styles/servicios.module.css";
import servicios from "../assets/servicios.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import {
  faScrewdriverWrench,
  faLightbulb,
  faGlobe,
  faNetworkWired,
} from "@fortawesome/free-solid-svg-icons";

const Servicios = () => {
  const iconos = {
    tools: faScrewdriverWrench,
    bulb: faLightbulb,
    globe: faGlobe,
    network: faNetworkWired,
  };

  const navigate = useNavigate();

  const [planActivo, setPlanActivo] = useState(null);

  const toggleDetalles = (indice) => {
    setPlanActivo(planActivo === indice ? null : indice);
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Nuestros Servicios</h1>
      <p className={styles.description}>
        En PCT brindamos soluciones tecnológicas personalizadas diseñadas para
        optimizar y potenciar el crecimiento de tu negocio o proyecto digital. A
        través de asesoría experta, desarrollo innovador y soporte técnico
        integral, te ayudamos a transformar ideas en realidades digitales
        eficientes, seguras y escalables. Nuestra misión es impulsar tu
        presencia en el mundo digital con herramientas avanzadas, estrategias
        efectivas y un enfoque adaptado a tus necesidades específicas.
      </p>

      <div className={styles.servicesRow}>
        {servicios.map((servicio, index) => (
          <div key={index} className={styles.column}>
            <div className={styles.serviceCard}>
              <div className={styles.cardTitle}>
                <FontAwesomeIcon
                  icon={iconos[servicio.icon]}
                  className={styles.iconoServicio}
                />{" "}
                {servicio.titulo}
              </div>
              <div className={styles.cardDescription}>
                {servicio.descripcion}
              </div>
              <button
                className={styles.button}
                onClick={() => toggleDetalles(index)}
                type="button"
              >
                {planActivo === index ? "Ocultar Detalles" : "Más Información"}
              </button>
              <div className={styles.detailsActiveContainer}>
                <div
                  className={`${styles.details} ${
                    planActivo === index ? styles.detailsActive : ""
                  }`}
                >
                  <ul>
                    {[
                      servicio.detalles1,
                      servicio.detalles2,
                      servicio.detalles3,
                      servicio.detalles4,
                      servicio.detalles5,
                      servicio.detalles6,
                      servicio.detalles7,
                    ]
                      .filter((detalle) => detalle && detalle.trim() !== "")
                      .map((detalle, i) => (
                        <li key={i}>{detalle}</li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* */}
      <div className={styles.buttonDiv}>
        <button
          className={styles.planesButton}
          onClick={() => navigate("/empresas/planes")}
          type="button"
        >
          Ver Planes Empresas
        </button>
      </div>
    </div>
  );
};

export default Servicios;
