import { useState } from "react";
import styles from "./styles/contactForm.module.css";

const ContactForm = () => {
  const [formValues, setFormValues] = useState({
    nombre: "",
    email: "",
    asunto: "",
    mensaje: "",
  });
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);
  const [resultMessage, setResultMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });

    setSubmitDisabled(
      !(
        formValues.nombre &&
        formValues.email &&
        formValues.asunto &&
        formValues.mensaje
      )
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("access_key", "a258a91d-2261-449b-8fc9-b22cd8398843");
    formData.append("nombre", formValues.nombre);
    formData.append("email", formValues.email);
    formData.append("asunto", formValues.asunto);
    formData.append("mensaje", formValues.mensaje);

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData,
      });
      const json = await response.json();
      setResultMessage(json.message);
    } catch {
      setResultMessage("Ups, algo salió mal!");
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.contactForm}>
      <label htmlFor="nombre">Nombre:</label>
      <input
        type="text"
        id="nombre"
        name="nombre"
        placeholder="Escriba su nombre"
        value={formValues.nombre}
        onChange={handleInputChange}
        required
      />

      <label htmlFor="email">Correo Electrónico:</label>
      <input
        type="email"
        id="email"
        name="email"
        placeholder="Escriba su correo"
        value={formValues.email}
        onChange={handleInputChange}
        required
      />

      <label htmlFor="asunto">Asunto:</label>
      <input
        type="text"
        id="asunto"
        name="asunto"
        placeholder="Escriba el motivo de contacto"
        value={formValues.asunto}
        onChange={handleInputChange}
        required
      />

      <label htmlFor="mensaje">Mensaje:</label>
      <textarea
        id="mensaje"
        name="mensaje"
        rows="8"
        cols="35"
        placeholder="Escriba su mensaje"
        value={formValues.mensaje}
        onChange={handleInputChange}
        required
      />

      <button
        name="submit"
        type="submit"
        className={styles.submitButton}
        disabled={isSubmitDisabled}
      >
        Enviar
      </button>
      <div id="result">{resultMessage}</div>
    </form>
  );
};

export default ContactForm;
