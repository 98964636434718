import ContactForm from "../components/ContactForm";
import SocialMedia from "../components/SocialMedia";
import styles from "./styles/contacto.module.css";

const Contacto = () => {
  return (
    <div className={styles.contactContainer}>
      <h1 className={styles.contactTitle}>Contacto</h1>
      <p className={styles.contactDescription}>
        ¡Nos encantaría saber de ti! Completa el formulario y te responderemos a
        la brevedad.
      </p>

      <ContactForm />
      <SocialMedia />

      <div className={styles.mapContainer}>
        <h2 className={styles.contactTitle}>Nuestra Ubicación</h2>
        <p className={styles.contactDescription}>
          Estamos ubicados en la comuna de Maipú, a pasos de la estación Monte
          Tabor.
        </p>
        <iframe
          title="Mapa de ubicación de PCT Solutions"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3327.9265824269214!2d-70.74978502450928!3d-33.477262173378946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c31151896349%3A0xdb6eeecc6c522a30!2sLeonardo%20Da%20Vinci%204813%2C%20Maip%C3%BA%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1sen!2scl!4v1742783264977!5m2!1sen!2scl"
          width="500"
          height="350"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default Contacto;
