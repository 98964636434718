import React, { useRef } from "react";
import useInView from "../hooks/useInView.js";
import styles from "./styles/animatedXCard.module.css";

const AnimatedXCard = ({ children, direction = "left", className = "" }) => {
  const ref = useRef();
  const isVisible = useInView(ref);

  const animationClass =
    direction === "left" ? styles.slideFromLeft : styles.slideFromRight;

  return (
    <div
      ref={ref}
      className={`${styles.animatedCard} ${className} ${
        isVisible ? `${styles.visible} ${animationClass}` : styles.hidden
      }`}
    >
      {children}
    </div>
  );
};

export default AnimatedXCard;
