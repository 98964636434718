import styles from "./styles/home.module.css";
import homeImage from "../assets/bg2.webp";
import { useEffect } from "react";
import AnimatedCard from "../components/AnimatedCard";

const Home = () => {
  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll(`.${styles.hidden}`);
      elements.forEach((el) => {
        const rect = el.getBoundingClientRect();
        if (rect.top < window.innerHeight * 0.9) {
          el.classList.add(styles.visible);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={styles.container}>
      <AnimatedCard className={styles.hero}>
        <h1 className={styles.title}>Prime C-Tech Solution</h1>
        <p>
          Soluciones tecnológicas a tu medida: soporte técnico, asesoría
          tecnológica y desarrollo web.
        </p>
        <AnimatedCard className={styles.contentContainer}>
          <p className={styles.description}>
            Ofrecemos soluciones tecnológicas a la medida de tus necesidades,
            brindando soporte técnico especializado, asesoría estratégica en
            transformación digital y desarrollo web innovador. Nuestro enfoque
            integral garantiza que cada servicio esté diseñado para optimizar el
            rendimiento, la seguridad y la eficiencia de tu negocio,
            permitiéndote aprovechar al máximo el potencial de la tecnología en
            un mundo digital en constante evolución.
          </p>
          <div className={styles.imageContainer}>
            <img
              src={homeImage}
              alt="Soluciones tecnológicas"
              className={styles.responsiveImage}
            />
          </div>
        </AnimatedCard>
      </AnimatedCard>
      <section className={styles.features}>
        <AnimatedCard className={styles.featureCard}>
          <h2>Soporte Técnico</h2>
          <p>
            Solucionamos problemas de hardware, software y redes de manera
            eficiente.
          </p>
        </AnimatedCard>
        <AnimatedCard className={styles.featureCard}>
          <h2>Asesoría Tecnológica</h2>
          <p>Te guiamos en la mejor estrategia tecnológica para tu negocio.</p>
        </AnimatedCard>
        <AnimatedCard className={styles.featureCard}>
          <h2>Desarrollo Web</h2>
          <p>Diseño y desarrollo de sitios web modernos y funcionales.</p>
        </AnimatedCard>
      </section>
    </div>
  );
};

export default Home;
