import { useEffect } from "react";
import styles from "./styles/about.module.css";
import homeImage from "../assets/bg1.webp";
import AnimatedCard from "../components/AnimatedCard";
import AnimatedXCard from "../components/AnimatedXCard";

const QuienesSomos = () => {
  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll(`.${styles.hidden}`);
      elements.forEach((el) => {
        const rect = el.getBoundingClientRect();
        if (rect.top < window.innerHeight * 0.9) {
          el.classList.add(styles.visible);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={styles.container}>
      <AnimatedCard className={styles.hero}>
        <h1 className={styles.title}>¿Quiénes Somos?</h1>
        <p className={styles.description}>
          En PCT Solution, nos dedicamos a brindar soluciones tecnológicas
          innovadoras diseñadas para impulsar el éxito de empresas y
          emprendedores. Nuestra misión es ofrecer servicios de soporte técnico,
          asesoría en transformación digital y desarrollo web adaptado a las
          necesidades de cada cliente.
        </p>
        <AnimatedCard className={styles.contentContainer}>
          <img
            src={homeImage}
            alt="Soluciones tecnológicas"
            className={styles.responsiveImage}
          />
        </AnimatedCard>
      </AnimatedCard>

      <section className={styles.features}>
        <AnimatedXCard direction="left" className={styles.contentSection}>
          <h2>Nuestro Enfoque</h2>
          <p>
            Creemos firmemente que la tecnología es la clave para impulsar la
            eficiencia y el crecimiento sostenible en las organizaciones. Nos
            comprometemos a diseñar y ejecutar estrategias personalizadas que no
            solo optimizan procesos y recursos, sino que también fomentan la
            innovación y la adaptabilidad en un entorno digital en constante
            evolución. Nuestro enfoque integral combina la experiencia técnica
            con una visión estratégica, garantizando resultados efectivos y
            medibles que transforman la forma en que las empresas operan y
            compiten en el mercado global.
          </p>
        </AnimatedXCard>
        <AnimatedXCard direction="right" className={styles.contentSection}>
          <h2>Compromiso y Calidad</h2>
          <p>
            Nos regimos por rigurosos estándares de calidad y una ética
            profesional inquebrantable, lo que nos permite ofrecer soluciones
            confiables y un servicio completamente transparente a nuestros
            clientes. Nuestra misión es garantizar que cada proyecto se ejecute
            con precisión y excelencia, priorizando siempre la integridad y la
            satisfacción del cliente en cada paso del proceso.
          </p>
        </AnimatedXCard>
        <AnimatedXCard direction="left" className={styles.contentSection}>
          <h2>Seguridad de la información</h2>
          <p>
            Operamos en estricto cumplimiento de la legislación chilena en
            materia de seguridad de la información y protección de datos,
            especialmente bajo los lineamientos de la Ley N° 19.628 de
            Protección de la Vida Privada. Implementamos políticas y medidas de
            seguridad robustas, alineadas con estándares internacionales como
            ISO/IEC 27001, para garantizar la confidencialidad, integridad y
            disponibilidad de la información de nuestros clientes. Nuestro
            compromiso es ofrecer soluciones tecnológicas seguras y confiables,
            asegurando el cumplimiento de todas las regulaciones y buenas
            prácticas del sector, y protegiendo la información sensible en un
            entorno digital en constante evolución.
          </p>
        </AnimatedXCard>
      </section>
    </div>
  );
};

export default QuienesSomos;
