import Navbar from "./Navbar";
import styles from "../components/styles/header.module.css";
import logo from "../assets/logo2-rbg.png";

const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.headerContainer}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="Logo" className={styles.logoImg} />
          {/* <h1 className={styles.siteName}>
            PCT<p className={styles.subName}>Solution</p>
          </h1> */}
        </div>
        <Navbar />
      </div>
    </header>
  );
};

export default Header;
