import React from "react";
import styles from "./styles/underConstruction.module.css";
import underConstructionImage from "../assets/404.webp";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>¡Ups! No encontramos esa página.</h1>
      <img
        src={underConstructionImage}
        alt="Trabajadores en construcción"
        className={styles.responsiveImage}
      />
      <button className={styles.returnButton} onClick={() => navigate("/")} type="button">
        Volver al inicio
      </button>
      <p className={styles.context}>
        Regresa pronto para descubrir todas las novedades.
      </p>
    </div>
  );
}

export default NotFound;
