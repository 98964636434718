import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Container from "../components/Container";
import styles from "./styles/base.module.css";
import Footer from "../components/Footer";

function Base() {
  return (
    <div className={styles.wrapper}>
      <Header />
      <Container>
        <Outlet />
      </Container>
      <Footer />
    </div>
  );
}

export default Base;
