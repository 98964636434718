import React, { useEffect, useState } from "react";
import styles from "./styles/noticias.module.css";

const Noticias = () => {
  const [noticias, setNoticias] = useState([]);

  useEffect(() => {
    const fetchNoticias = async () => {
      try {
        const response = await fetch(
          `https://newsdata.io/api/1/news?apikey=pub_776107c771299c4155c7a0b3a1918bf172466&q=technology&language=es&category=technology`
        );
        const data = await response.json();
        setNoticias(data.results);
      } catch (error) {
        console.error("Error al obtener las noticias:", error);
      }
    };

    fetchNoticias();
  }, []);

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Últimas Noticias de Tecnología</h1>
      <div className={styles.grid}>
        {noticias.slice(0, 6).map((noticia, index) => (
          <div key={index} className={styles.card}>
            <img
              src={noticia.image_url}
              alt={noticia.title}
              className={styles.image}
            />
            <h2 className={styles.cardTitle}>{noticia.title}</h2>
            <p className={styles.description}>{noticia.description}</p>
            <a
              href={noticia.link}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              Leer más
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Noticias;
