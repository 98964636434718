import { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./styles/navbar.module.css";
import { FiMenu, FiX } from "react-icons/fi";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);

  const toggleMenu = () => {
    const newState = !menuOpen;
    setMenuOpen(newState);
    if (!newState) {
      setSubmenuOpen(false);
    }
  };

  const closeMenu = () => {
    setMenuOpen(false);
    setSubmenuOpen(false)
  };

  const toggleSubmenu = () => {
    setSubmenuOpen((prev) => !prev);
  };

  return (
    <nav className={styles.navbar}>
      <button
        name="menu"
        className={styles.menuButton}
        onClick={toggleMenu}
        aria-label={menuOpen ? "Cerrar menú" : "Abrir menú"}
      >
        {menuOpen ? <FiX /> : <FiMenu />}
      </button>
      <ul className={`${styles.navLinks} ${menuOpen ? styles.showMenu : ""}`}>
        <li>
          <Link to="/" onClick={closeMenu}>
            Inicio
          </Link>
        </li>

        <li
          className={styles.dropdown}
          onMouseEnter={() => window.innerWidth > 768 && setSubmenuOpen(true)}
          onMouseLeave={() => window.innerWidth > 768 && setSubmenuOpen(false)}
        >
          <span className={styles.dropdownTitle} onClick={toggleSubmenu} role="button" tabIndex="0">
            Planes y Servicios
          </span>
          <ul className={`${styles.submenu} ${ submenuOpen ? styles.showSubmenu : "" }`}>
            <li>
              <Link to="/hogar/servicios" onClick={closeMenu}>
                Servicios
              </Link>
            </li>
            <li>
              <Link to="/empresas/planes" onClick={closeMenu}>
                Planes
              </Link>
            </li>
          </ul>
        </li>

        <li>
          <Link to="/nosotros" onClick={closeMenu}>
            Nosotros
          </Link>
        </li>
        <li>
          <Link to="/contacto" onClick={closeMenu}>
            Contacto
          </Link>
        </li>
        <li>
          <Link to="/preguntas" onClick={closeMenu}>
            Faq's
          </Link>
        </li>
        <li>
          <Link to="/noticias" onClick={closeMenu}>
            Noticias
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
